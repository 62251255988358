.user_mode {

	#panelTwo {
		width: calc(100% - 315px);
		padding-right: 0;
		padding-left: 0;

	}

	&#home,
	&#assets,
	&#siteGuide,
	&#hideRail {

			#panelTwo {
				width: 100%;
				padding-right: 15px;
				padding-left: 15px;
			}

		}

}

.layoutContainer { padding-left: 0; }

#assets,
#siteGuide,
#hideRail{

	.right-rail { display: none; }

	&.user_mode #panelTwo { width: 100%; }

}

.right-rail {
	width: 100%;
	max-width: 300px;
	float: left;

	.edit_mode & { display: none; }

	.column {
		width: 100%;
		padding: 0;
	}

	.layoutContainer { padding-left: 0; }

	.newsAggregatorElement {

		.extendedOptions { display: none; }

		.item.condensed:last-of-type {
			border-bottom: none;
			// border-width: 1px;
		}

		.item.condensed {
			background: transparent; //$light-gray
			padding: 15px;
      border: none;
			border-bottom: solid 1px $gray;
			border-width: 0px 1px 1px 1px;

			> a {
				padding: 0;
				margin-bottom: 10px;
				margin-right: 10px;
				height: 65px;
		    width: 110px;
		    float: left;
		    overflow: hidden;
		    position: relative;

				img {
				height: auto;
			    width: 100%;
			    position: absolute;
			    top: 50%;
			    left: 50%;
			    -webkit-transform: translate(-50%, -50%);
			    transform: translate(-50%, -50%);
				}

			}

			.details { padding-left: 120px; }

			ul {

				h4 {
					line-height: normal;
					font-size: 14px;
					font-family: "Lato", "sans-serif";
					text-transform: none;

					a {
						font-weight: 400;
					}

				}

			}

			.teaser {
				font-size: 12px;
				line-height: normal;
				max-height: 35px;
  			overflow: hidden;
  			text-overflow: ellipsis;
  			display: -webkit-box;
  			-webkit-line-clamp: 2;
  			-webkit-box-orient: vertical;
  			margin: 3px 0;
			}

		}

	}

	.commentsAndMore .readMore { display: none; }

}

@media screen and (max-width: 1024px){

	.user_mode #panelTwo { width: 100%; }

	.right-rail {
		max-width: 100%;
		padding: 0 20px;

		.heroPhotoElement {
			text-align: center;

			img { max-width: 100%; }

		}

		.newsAggregatorElement .item > a {
			width: 200px;
			padding: 3px 15px 7px 0px;
		}

	}

}

@media screen and (max-width: 768px){

	.user_mode #panelTwo { padding-right: 15px; }

  .right-rail-layout {

      #panelTwo {
        width: 100% !important;
      }

      #panelThree {
        width: 100% !important;
        max-width: 100% !important;
      }

  }

}



.right-rail-layout {

    #panelTwo {
      width: calc(100% - 330px);
      max-width: 1085px;
      float: left;
    }

    #panelThree {
      width: 100%;
      max-width: 330px;
      float: left;
      padding-right: 15px;

      .column {
        padding-left: 0;
      }

    }

}

div#panelTwo.noRightRail {
  width: 100% !important;
  padding: 15px;
}
