/* Content Tabbed Navigation
-------------------------------------------------------------------- */

.contentTabs {
  display: block;
  list-style-type: none;
  text-align: left;
  width: 100%;
  margin: 10px 0;
  //border-bottom: 3px solid #ececec;

  &:after { clear: both; }

  &:before,
  &:after {
    content: " ";
    display: table;
  }

  li {
    @include content-tab-item;
    
    span {
      background-repeat: no-repeat;
      background-position: bottom left;
      text-decoration: none;
      display: block;

      a {
        @include content-tab;
        color: #fff;
        background-color: $accent-color;
        transition:background-color 300ms;
        font:bold 1em/1.5em $fontOne;

        span { display: inline; }
      }
    }

    &:hover,
    &.selected {
      span a{
        background-color: $link-color;
        color: #fff;
      }
      //
      //border-bottom: 5px solid $link-color; 
    }
  }

  &.rapidTabsConstruction a.tabLink {
    padding-top: 4px;
    padding-left: 8px;
    display: inline-block;
    background: none;
  }
}

.pageElementAdmin .rosterPlayerStatElement .contentTabs { margin: 0; }

.dataTable .disabled {
  color: #000;
  text-decoration: none;

  &:hover { text-decoration: underline; }
}


/* Loading Tab Animation
-------------------------------------------------------------------- */
#contentTabsLoader {
  text-align: center;
  clear: both;
  padding: 25px;
}


/* Rapid Tabs Construction
-------------------------------------------------------------------- */
.rapidTabsConstruction {
  background: #FDDD40;
  margin-bottom: 0;
  border-top: solid 1px #DDA301;
  padding: 0 0 1px;

  li {
    border-right: solid 1px #DDA301;
    padding-right: 0;

    a { font-size: 14px; }

    span {
      background: none;
      padding-left: 0;

      a {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .selected { background: #FDE872; }
}
