$darkGrey: #222;
.text{
  h2, h3{
    border-bottom: 3px solid $accent-color;
    margin-bottom: 10px;
  }
}

body {
  &.user_mode {
    #topNav{
      .theme-main-nav .theme-nav.theme-nav-style-dropdown li.home {
        opacity: 0;
        width: 0;
      }
    }
  }
  &.home {
    .theme-sub-nav {
      display: none !important;
    }
    #topNavPlaceholder {
      height: 280px;
    }
  }
}

.theme-nav-forward.page_node_2567067 {
  display: none !important;
}


#topNav{
  //position: relative;
  .theme-search-bar{
    height: auto;
  }
  .site-tagline-text{
    font:bold 48px/1.5em $fontOne;
    height: auto;
    padding: 10px 0px;
    b{
      font:100 48px/1.5em $fontOne;
    }
  }
}
#sn-site-logo{
  border: 0px;
  background: transparent;
  height: 200px;
  width: 150px;
  left: 49%;
}
#topNav .theme-search-wrapper{
  border-bottom: 4px solid $accent-color;
}
#topNav .theme-nav-style-dropdown > .theme-nav-item{
  border-right: 0px;
}
#topNav .theme-main-nav{
  background-color: $darkGrey;
}
#topNav .theme-main-nav{
  border-bottom: 0px;
}
.has-sub-nav #topNavPlaceholder{
  height: 320px;
}
#topNavPlaceholder{
  height: 280px;
  border-bottom: 0px;
}
.snFooterContainer{
  background-color: $accent-color;
  padding-top: 30px;
  padding-bottom: 30px;
  .brand{
    display: block;
    width: 60%;
    margin: auto;
    text-align: center;
    position: relative;
    margin-bottom: 5px;
    .sn-site-logo-wrap{
      .sn-site-logo-background{
        background-repeat: no-repeat;
        background-position: center top;
        background-size: contain;
        width: 75px;
        height: 75px;
        margin: auto;
        display: block;
        background-color: $accent-color;
        padding: 0px 10px;
        z-index: 2;
        &:before, &:after{
          height: 2px;
          display: block;
          position: absolute;
          content: '';
          background-color: #fff;
          width: 40%;
          top:45%;
          z-index: 1;
        }
        &:before{
          left:0;
          content: '';
        }
        &:after{
          right: 0;
          content: '';
        }
      }
    }
  }
  #siteFooter{
    text-align: center;
    padding: 0px;
    *{
      color: #fff;
      font-size: 10px;
    }
    ul li a{
      color: #fff;
      text-decoration: underline;
    }
  }
  .globalFooter{
    .text{
      text-align: center;
      p{
        padding: 0px;
        text-align: center;
      }
      a{
        &:last-child{
          border-right: 0px;
        }
        text-align:center;
        display: inline-block;
        border-right: 1px solid #fff;
        padding: 0px 10px;
        color: #fff;
        font: 100 .9em/1em $fontOne;
      }
    }
  }
}
.sn-call-to-action{
  padding: 0px;
  &:before, &:after{
    display: none;
  }
}
#home #displayBodyHeader{
  display: none;
}
#displayBodyHeader{
  margin-bottom: 15px;
}
.fontSize18{
  h4{
    font-size: 18px !important;
  }
}
.fixedSlide{
  .sn-call-to-action.sn-variation-slide-up {
    &.sn-call-to-action{
      &:before, &:after{
        display: none;
      }
    }
    .sn-call-to-action-title{
      display: inline-block;
      border-bottom: 2px solid #fff;
      border-top: 2px solid #fff;
      font: bold 24px/1.5em $fontOne;

      span{
        display: inline-block;
      }
    }
    .sn-call-to-action-overlay {
      height: 100%;
      background: rgba($accent-color, 0.7);
    }
  }
}
.pageElement{
  > h3{
    font:bold 24px/1.5em $fontOne;
    background: transparent;
    border-bottom: 3px solid $accent-color;
    width: 100%;
    box-shadow: none;
    color: $link-color;
    padding-bottom: 2px;
    padding-left: 0px;
    margin-bottom: 15px;
    &:after,&:before{
      display: none;
    }
  }
}

.newsAggregatorElement .item{
  margin-bottom: 15px;
  border-top-width: 1px !important;
  padding: 15px;
  //
  &:nth-of-type(1){
    border: 1px solid #cacaca;
  }
  &:last-child{
    border: 0px;
    border-bottom: 1px solid $link-color;
  }
  border: 1px solid #cacaca;
  padding: 20px;
  h4{
    color: #222;
    a{
      color: #222;
      font:bold 16px/1.4em $fontOne;
      text-decoration: none;
    }
  }
  .dateAuthor{
    .newsAuthor{
      font:normal 14px/1.4em $fontOne;
      color: #222;
      display: none;
    }
    .newsDate{
      display: block;
      font:normal 11px/1.4em $fontOne;
      color: #222;
    }
  }
  .teaser{
    display: block;
    font:normal 14px/1.4em $fontOne;
    color: #222;
    text-transform: none;
  }
  .commentsAndMore{
    display: none;
  }
  img{
    border: 1px solid #cacaca;
  }
}
.pageElement.linkElement{
  h4 a{
    background: $accent-color;
    box-shadow: none;
    font: 100 1em/1.5em $fontOne;
    &:after{
      content: '\f054';
      color: #fff;
      font-size: .75em;
      transition:padding-left 300ms;
    }
    &:hover{
      &:after{
        content: '\f054';
        padding-left: 5px;
      }
    }
  }
}
.networkContainer{
  width: 100%;
  margin: auto;
  display: block;
  height: 40px;
  background: #fff;
}
.newsSlideShow-container{
  margin-bottom: 0px;
  .newsSlideShow-thumbnails, .newsSlideShow-controls{
    //display: none;
  }
  .newsSlideShow-headline{
    bottom: 0px;
    h1{
      margin: 0px;
      width: 100%;
      background: rgba($link-color,.75);
      &:before{
        display: none;
      }
    }
    p{
      //display: none;
      padding-left: 0px;
      margin: 0px;
      &:before{
        display: none;
      }
      width: 100%;
      span{
        background: rgba($link-color,.75);
        padding: 0px;
        width: 100%;
        max-width: 100%;
        padding-left: 20px;
        text-indent: 0px;
      }
      .newsSlideShow-more{
        display: none;
      }
    }
  }
}
.theme-nav-style-slide .theme-slide-nav-toggle{
  line-height: 39px;
}
// Placeholder styles for when top nav goes fixed. If there is no sub nav and no
// banner graphic, this fills in the gap and visually blends with the page content.

#topNavPlaceholder {
  // border-bottom: $sub-nav-height solid #fff;
  max-width: $maxWidth;
  margin: auto;
  transition: height $transition-linear;
  position: relative;

  .lbOn.lbox_bound { display: none !important; }

  .headerAd {
    height: 90px;
    width: 100%;
    max-width: 728px;
    position: absolute;
    right: 0;
    bottom: 20px;

    a, img {
      width: 100%;
      height: 100%;
    }
    a{
      display: block;
      transition: $transition-standard;
      &:hover { opacity: .95; }
    }
    .lbOn { display: none; }

    a[title*="click"] { display: none; }

  }

  .presentedBy {
    height: 90px;
    width: 90px;
    position: absolute;
    bottom: 20px;
    left: 130px;

    .pageElement.heroPhotoElement {
      margin: 0;

      .cutline {
        color: #000;
        font-style: normal;
        position: absolute;
        background: transparent;
        font:normal 10px $fontTwo;
        top: -30px;
        padding: 0;
        text-align: center;
        border: 0px;
      }

    }
    a{
      margin-top: 40px;
      display: block;
      transition: $transition-standard;

      &:hover { opacity: .9; }
    }
    img { width: 100%; border: 0px; }

  }

  &.page-has-banner { border-color: transparent; }
}

#topNav.nav-fixed + #topNavPlaceholder {
  height: $nav-placeholder-height-fixed;
}
//click to go to 'Home'
a.theme-nav-link[title*="click to go to 'Home'"]{
  display: none;
}
@media only screen and (max-width: 1024px) {
  .globalFooter{
    .text{
      text-align: center;
      a{
        line-height: 2em !important;
        &:first-child{
          border:none !important;
        }
        display:block !important;
        border: none !important;
      }
    }
  }
  #siteFooter{
    ul li{
      display: block !important;
      a{
        display: block !important;
      }
    }
  }
  #siteFooter li#poweredByNGIN a{
    margin: auto;
    display: block;
    text-align: center;
  }
  .snFooterContainer{
    .brand{
      .sn-site-logo-wrap{
        .sn-site-logo-background{
          &:before, &:after{
            width: 0px;
          }
        }
      }
    }
  }
  .dataTable tbody td, .statTable tbody td, .stat_table tbody td, .dataTable tbody td:first-child, .statTable tbody td:first-child, .stat_table tbody td:first-child, .dataTable tbody td:last-child, .statTable tbody td:last-child, .stat_table tbody td:last-child{
    border: 0px;
  }

  .slide-nav-open {
    #accountNav {
      z-index: 399;
    }
  }

  #accountNav { //added from USL with modification
    background: transparent;
    z-index: 999;
    margin-left: 44px;
    width: calc(100% - 44px);

    .secondary a {
      color: white;
      @media only screen and (max-width: 424px) {
        span {
          display: none;
        }
      }

    }

    #logout { float: right; }

  }

  #mobileLogo { //added from USL
    width: 50px;
    background-size: auto 75%;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 5px;
  }

  #topNav { //added from USL

    .theme-search-wrapper {
      display: block;
      height: 44px;
    }

    // overwrite default theme search bar styles when logged out
    .theme-search-bar {
      height: 44px;
      left: 40px;
      line-height: 44px;
      max-width: 100% !important;
    }

    &.has-site-logo,
    &.has-site-logo.nav-fixed,
    &.nav-fixed {

      .theme-search-bar {
        height: 44px;
        left: 40px;
        line-height: 44px;
      }

    }
   .site-tagline-text { // new
     font-size: 28px;
     b {
       font-size: 28px;
     }
   }

  }

  html.collapsed-mobile-nav .nav-fixed .theme-mobile-nav { display: block !important; }

  html.collapsed-mobile-nav:not(.slide-nav-open) .theme-slide-nav-toggle { background: $highlight-color;} // new

}
@media only screen and (max-width: 640px) {
  .newsSlideShow-container .newsSlideShow-headline{
    padding-bottom: 0px;
  }
}

.has-mobile-nav {
  #PageSearchBoxTopNav {
    visibility: hidden;
  }
}
